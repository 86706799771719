import { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';


function Callback() {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');

        if (code) {
            Auth.federatedSignIn({ code })
                .then(() => {
                    navigate('/dashboard'); // Redirect to the dashboard or another page for logged-in users
                })
                .catch(error => {
                    console.error(error);
                    navigate('/'); // Redirect to the home page in case of error
                });
        }
    }, [navigate]);

    return null; // This component does not need to render anything
}

export default Callback;

