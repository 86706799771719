module.exports = {
    getUser: function () {
        const user = sessionStorage.getItem('user');
        if (user === 'undefined' || !user) {
            return null;
        } else {
            return JSON.parse(user);
        }
    },

    getToken: function () {
        return sessionStorage.getItem('token');
    },

    setUserSession: function (user, token) {
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', token);

    },

    resetUserSession: function () {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');

    },

    setLogoutTimer: function (callback) {
        const delay = 55 * 60 * 1000;
        return setTimeout(callback, delay);
    },

    clearLogoutTimer: function (timerId) {
        clearTimeout(timerId);
    },

    isAuthenticated: function (userPool) {
        const currentUser = userPool.getCurrentUser();

        if (currentUser) {
            return new Promise((resolve, reject) => {
                currentUser.getSession((err, session) => {
                    if (err) {
                        reject(err);
                    } else {
                        if (session.isValid()) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }
                });
            });
        } else {
            return Promise.resolve(false);
        }
    },

    parseHash: function (hash) {
        return hash
            .substring(1) // remove the leading '#'
            .split('&')
            .reduce((result, item) => {
                const parts = item.split('=');
                result[parts[0]] = decodeURIComponent(parts[1]);
                return result;
            }, {});
    }

}
