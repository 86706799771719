import React, { useState, useEffect } from 'react';
import './App.css';
import './css/annotateApp.css'
import './css/renamed_desktop-1.css'
import './css/globals.css'
import './css/styleguide.css'
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import DiscoverDeeper from './components/DiscoverDeeper';
import AboutScholarly from './components/AboutScholarly';
import Footer from './components/Footer';
import AnnotatorApp from './components/AnnotatorApp';
import HeaderLogin from './components/HeaderLogin';
import { Auth } from 'aws-amplify';
// import { withAuthenticator } from '@aws-amplify/ui-react';
import Callback from './components/Callback';
import Dashboard from './components/Dashboard';
import HeaderPremium from './components/HeaderPremium';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        checkAuthState();
    }, []);

    const checkAuthState = async () => {
        try {
            const userData = await Auth.currentAuthenticatedUser();
            setIsAuthenticated(true);
            setUser(userData);
            console.log(userData);
            console.log('user: ', user);
        } catch (e) {
            setIsAuthenticated(false);
            setUser(null);
        }
    };

    return (
        <div className='App'>
            <BrowserRouter>

                <header>
                    {isAuthenticated ? (
                        <HeaderPremium user={user} />
                    ) : (
                        <HeaderLogin />
                    )}
                </header>


                {/* <div className='main-content'> */}


                <Routes>
                    <Route path="/" element={
                        // <HomeContent />
                        <div className="main-content">
                            <DiscoverDeeper />
                            <AboutScholarly />
                        </div>
                    } />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/callback" element={<Callback />} />
                    <Route path="/AnnotatorApp" element={
                        <ProtectedRoute isLoggedIn={isAuthenticated} >
                            <AnnotatorApp />
                        </ProtectedRoute>
                    } />
                </Routes>
            </BrowserRouter>
            <footer className='footer-content'>
                <Footer />
            </footer>

        </div >
    );
}

export default App;




// export default App;





// const loginUrl = `https://scholarlyaidocs-signin.auth.us-east-1.amazoncognito.com/login?client_id=5qooskbniim6st2o6hhhb6sk4e&response_type=code&scope=email+openid+phone&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fcallback';

// const handleLoginClick = () => {
//     window.location.href = loginUrl;
//     // alert('Button was clicked!');
//     // window.open(loginUrl);
//     // setIsModalOpen(true);
// };



{/* <Route path="/callback" component={CallbackComponent} /> */ }
{/*<Route path="/callback" element={<Callback />} /> }

<Route path="/" element={
                            // <HomeContent />
                            <div className="main-content">
                            </div>
                        } />
                        <Route path="/AnnotatorApp" element={<AnnotatorApp />} /> 
                        <Route path="/AnnotatorApp" element={
                            <ProtectedRoute >
                            <AnnotatorApp />
                            </ProtectedRoute>
                        } />*/}