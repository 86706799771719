import React from 'react';
import { Auth } from 'aws-amplify';
import smallLogo from '../img/asset-11-1.svg';

function HeaderLogin() {
    const handleLoginClick = () => {
        Auth.federatedSignIn();
    };

    return (
        <div className='main-headers'>
            <div>
                <img alt="company-logo" src={smallLogo} />
                <h1 className="company-name">Scholarly AI Docs</h1>
            </div>
            <div>
                <button id='main-login-btn' onClick={handleLoginClick}>
                    Login
                </button>
            </div>
        </div>
    );
}

export default HeaderLogin;




// import React from 'react';
// import smallLogo from '../img/asset-11-1.svg';



// function HeaderLogin({ onLoginClick }) {
    
//     return (
//         <div className='main-headers'>
//             <div>
//                 <img alt="company-logo" src={smallLogo} />
//                 <h1 className="company-name">Scholarly AI Docs</h1>
//             </div>
//             <div>

//                 <button id='main-login-btn' onClick={onLoginClick}>
//                     Login
//                 </button>

//             </div>
//         </div>
//     );
// }

// export default HeaderLogin;


// import LoginBtn from './LoginBtn';

// import Modal from './Modal';
// const loginUrl = `https://login-scholarlyaidocs.auth.us-east-1.amazoncognito.com/oauth2/authorize?response_type=code&client_id=YOUR_CLIENT_ID&redirect_uri=https://YOUR_CLOUDFRONT_DISTRIBUTION_URL/redirect_uri&state=YOUR_STATE&scope=openid+profile+aws.cognito.signin.user.admin`;

// function parentToHandleLoginClick() {
//     handleLoginClick;
// }
/*<Modal
    showModal={showModal}
    toggleModal={setShowModal}
    username={username}
    setUsername={setUsername}
    password={password}
    setPassword={setPassword}
    onLoginSuccess={onLoginSuccess}
/>*/