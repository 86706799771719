import React, { useRef, useState, useEffect } from 'react';


function OptionsWidget() {
    const widgetRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [option, setOption] = useState('');

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (isResizing && widgetRef.current) {
                const widget = widgetRef.current;
                const newHeight = event.clientY - widget.offsetTop;
                if (newHeight > 50) { // a minimum height threshold
                    widget.style.height = `${newHeight}px`;
                }
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            window.addEventListener('touchmove', handleMouseMove);
            window.addEventListener('touchend', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [isResizing]);


    return (
        <div className="options-widget" ref={widgetRef}>
            <h3>Choose Option</h3>
            <select value={option} onChange={(e) => setOption(e.target.value)}>
                <option value="option1">Option 1</option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
            </select>
            <div className="resizer" onMouseDown={() => setIsResizing(true)}></div>
        </div>
    );
}

export default OptionsWidget;
