import React from 'react';
import UploadWidget from './UploadWidget';
import OptionsWidget from './OptionsWidget';

function ResizableWidget({ setIsResizing }) {
    return (
        <div className="widget-generic">
            <OptionsWidget />
            <UploadWidget />
            <div className="resizer-generic" onMouseDown={() => setIsResizing(true)}></div>
        </div>
    );
}

export default ResizableWidget;