import React, { useRef, useState, useEffect } from 'react';

function UploadWidget({ username }) {
    const widgetRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadStatus, setUploadStatus] = useState(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (isResizing && widgetRef.current) {
                const widget = widgetRef.current;
                const newHeight = event.clientY - widget.offsetTop;
                if (newHeight > 50) { // a minimum height threshold
                    widget.style.height = `${newHeight}px`;
                }
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);

            window.addEventListener('touchmove', handleMouseMove);
            window.addEventListener('touchend', handleMouseUp);

        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);

            window.removeEventListener('touchmove', handleMouseMove);
            window.removeEventListener('touchend', handleMouseUp);
        };
    }, [isResizing]);

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         // Process the file here
    //     }
    // };



    const handleFileChange = async (e) => {
        setUploading(true);
        const file = e.target.files[0];
        if (file && username) {
            const apiUrl = `${process.env.UPLOAD_API_URL}/${username}/${file.name}`; // UPLOAD_API_URL
            try {
                const response = await fetch(apiUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        'x-api-key': process.env.X_API_KEY
                    }
                });
                if (response.ok) {
                    console.log('File uploaded successfully!');
                    setUploadStatus('File uploaded successfully!');
                } else {
                    const errorText = await response.text();
                    console.error('Failed to upload file:', errorText);
                    setUploadStatus(`Failed to upload file: ${errorText}`);
                }
                setUploading(false);
            } catch (error) {
                console.error('Error uploading file:', error);
                setUploadStatus('Error uploading file. Please try again.');
            }
        }
    };




    return (
        <div className="upload-widget" ref={widgetRef}>
            <h3>Upload File</h3>
            <input type="file" onChange={handleFileChange} />
            <div className="resizer" onMouseDown={() => setIsResizing(true)}></div>
            {uploading ? <p>Uploading...</p> : null}
            {uploadStatus ? <p>{uploadStatus}</p> : null}
        </div>
    );
}

export default UploadWidget;
