import smallLogo from '../img/asset-11-1.svg';
import { resetUserSession } from '../service/AuthService';
import { NavLink, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';


function HeaderPremium({ user }) {

    const navigate = useNavigate();

    const handleLogOutSuccess = async () => {
        try {
            await Auth.signOut();
            resetUserSession();
            navigate('/'); // redirect to home or login page after logout
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };


    return (
        <div className="main-headers">
            <div>
                <img alt="company-logo" src={smallLogo} />
                <h1 className="company-name">Scholarly AI Docs</h1>
            </div>

            <div className='header-menu'>
                <NavLink className="header-navLink" to="/">Home</NavLink>
                <span className="header-menu-separator"></span>
                <NavLink className="header-navLink" to="/AnnotatorApp">Annotator</NavLink>
                {/* <span className="header-menu-separator"></span> */}
                {/* <button onClick={handleLogOutSuccess} className="header-navLink">Signout</button> */}
                {/* <NavLink onClick={handleLogOutSuccess} className="header-navLink">Signout</NavLink> */}
                {/* <button id='main-login-btn' onClick={() => handleLogOutSuccess} > Signout</button> */}
            </div>

            <div className='logoutBtn-with-name'>
                <p>Hello{(user?.attributes?.given_name && ',') || ''}!</p>

                <button id="main-login-btn" onClick={handleLogOutSuccess}>
                    Sign Out
                </button>
            </div>
        </div>
    );
}

export default HeaderPremium;

