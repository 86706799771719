import React from 'react';
import frame1Img from '../img/frame-1.svg';

function Footer() {
    return (
        <div className="rectangle-2 rectangle">
            <div className="frame-11">
                <h1 className="coming-soon">COMING SOON</h1>
                <img alt="Frame 1" className="frame" src={frame1Img} />
            </div>
        </div>
    );
}

export default Footer;