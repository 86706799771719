import { useEffect } from 'react';
import { Auth } from 'aws-amplify';

function Dashboard() {
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(user => console.log(user))
            .catch(err => console.log(err));
    }, []);

    return (
        <div>
            Welcome to your dashboard!
        </div>
    );
}

export default Dashboard;
