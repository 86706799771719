import { Navigate, useLocation } from 'react-router-dom';

function ProtectedRoute({ children, isLoggedIn }) {
    let location = useLocation();

    if (isLoggedIn) {
        return children;  // If logged in, render the children component.
    } else {
        return <Navigate to="/" state={{ from: location }} />; // If not, redirect to homepage.
    }
}

export default ProtectedRoute;