import React from 'react';
import discoverImg from '../img/rectangle-1.svg'

function DiscoverDeeper() {
    return (
        <div className="frame-10">
            <img alt="Rectangle 1" className="rectangle-1 rectangle" src={discoverImg} />
            <p className="discover-deeper-whe">
                <span className="span0">DISCOVER DEEPER,</span><span className="span1"> </span>
            </p>
            <p className="discover-deeper-whe_line2">
                <span className="span2">WHERE INSIGHT
                    MEETS INTELLECT</span>
            </p>
        </div>
    );
}

export default DiscoverDeeper;