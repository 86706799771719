import React, { useState, useRef, useEffect } from 'react';

function AdjustableCanvas() {
    const [isResizing, setIsResizing] = useState(false);
    const containerRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (isResizing && containerRef.current) {
                const container = containerRef.current;
                container.style.width = event.clientX - container.offsetLeft + 'px';
                container.style.height = event.clientY - container.offsetTop + 'px';
            }
        };

        const handleMouseUp = () => {
            if (isResizing) {
                setIsResizing(false);
            }
        };

        if (isResizing) {
            document.addEventListener('mousemove', handleMouseMove);
            document.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    return (
        <div className="canvas-container" ref={containerRef}>
            <canvas id="myCanvas"></canvas>
            <div className="resizer-canvas" onMouseDown={() => setIsResizing(true)}></div>
        </div>
    );
}

export default AdjustableCanvas;

