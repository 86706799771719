import React, { useRef, useState, useEffect } from 'react';

import '../css/annot_styles.css';
import AdjustableCanvas from './AdjustableCanvas';
import ResizableWidget from './ResizableWidget';


const AnnotatorApp = ({ username }) => {
    const widgetRef = useRef(null);
    const [isResizing, setIsResizing] = useState(false);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (isResizing && widgetRef.current) {
                const widget = widgetRef.current;
                widget.style.width = `${event.clientX - widget.offsetLeft}px`;
            }
        };

        const handleMouseUp = () => {
            setIsResizing(false);
        };

        if (isResizing) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);

    return (
        <div className="widget-page">
            <div className='left-widgets' ref={widgetRef} >
                <ResizableWidget setIsResizing={setIsResizing} username={username} />
            </div>
            <div className="right-panel">
                <AdjustableCanvas />
            </div>
        </div>
    );
}

export default AnnotatorApp;










































// {/* <HeaderLogOut /> */ }
// <div className="centered-content">

//     <div className="centered-container" id="canvas-container">
//         <img alt="logo" height="800" id="myImage" width="600" />
//         <canvas height="800" id="drawing-canvas" width="600"></canvas>
//     </div>
//     <br />
//     <div>
//         <p id="coordsDisplay">Coords: Not set</p>
//         <p id="bboxDisplay">BBox: Not set</p>
//     </div>
//     <div className="button-container">
//         <button className="styled-button" onclick="prevImage()">Previous Image</button>
//         <button className="styled-button" onclick="nextImage()">Next Image</button>
//     </div>

//     <div className="select_color">
//         <label className='color-select'><input checked="" id="red"
//             name="color" type="radio" value="#FF0000" /> Red</label>
//         <label className='color-select'><input id="green" name="color"
//             type="radio" value="#00FF00" /> Green</label>
//         <label className='color-select'><input id="blue" name="color"
//             type="radio" value="#0000FF" /> Blue</label>

//     </div>
//     <br />

//     <div className="save-buttons">
//         <button className="styled-button" onclick="saveImage()">Save Image</button>
//         <button className="styled-button" onclick="saveBboxesToFile()">Save BBoxes to File</button>
//     </div>
//     <div id="bbox_list_container">
//         <div id="bboxes_list">
//         </div>
//         <button className="styled-button" hidden="" id="delete_btn" onclick="deleteBbox()">Delete Bbox</button>
//     </div>
// </div>