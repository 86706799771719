const awsConfig = {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_KbcXZuoPK', // find this in the Cognito console
    aws_user_pools_web_client_id: '3qbl2h85tobpcm0f1ebq9ogqqs', // find this in the Cognito console
    oauth: {
        domain: 'signin-scholarlyaidocs.auth.us-east-1.amazoncognito.com',
        scope: ['email', 'openid', 'phone'],
        redirectSignIn: 'https://scholarlyaidocs.com/callback',
        redirectSignOut: 'https://scholarlyaidocs.com',
        responseType: 'code',
    },
    // ... (add other configurations if necessary)
};

export default awsConfig;