import React from 'react';
// import discoverImg from '../img/rectangle-1.svg'

function AboutScholarly() {
    return (
        <div className="frame-6">
            <h2 className="about-scholarly-ai-docs"> About Scholarly AI Docs</h2>
            <p className="welcome-to-scholarly">
                Welcome to Scholarly AI Docs, where the cutting-edge world of artificial intelligence meets the
                profound
                depth of scholarly research. Our vision is to transform how scholars, researchers, and academics
                engage with
                and derive insights from an extensive body of work. Unlike other platforms, Scholarly AI Docs is
                crafted
                specifically for the academic realm, ensuring an unparalleled synthesis of machine intelligence and
                scholarly rigor. We understand the unique challenges and intricacies of academic literature, and
                we're
                committed to offering tools that elevate your research process. <br />The future of scholarly
                interaction is on the horizon. Stay with us as we usher in a new era of AI-enhanced academic
                exploration.
                The full platform will be unveiled soon. Join us in charting the next chapter of scholarly
                discovery.
            </p>
        </div>
    );
}

export default AboutScholarly;